body {
  margin: 0;
  font-family: 'Helvetica Now Display', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 320px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  box-sizing: border-box;
}

div {
}

div::after {
  content: "";
  clear: both;
  display: table;
}

.nav {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  background-color: white;
}

.nav ul {
  float: right;
  display: block;
  padding: 0;
  margin: 0;
  width: 89%;
  display: flex;
  justify-content: space-between;
}

.nav ul li {
  display: inline-block;
  list-style-type: none;
}

.nav ul li:first-child {
  padding-left: 12px;
}

.nav ul li:last-child {
  margin-right: 12px;
}

.nav ul li a {
  color: #999;
  font-size: 14px;
  font-weight: bold;
  line-height: 38px;
  padding: 5px 0;
  font-style: normal;
  text-decoration: none;
}

#home .nav ul li a {
  color: #000;
}

.nav ul li a.active {
  color: #000;
}

@media screen and (min-width: 768px) {
  .nav ul {
    width: 50%;
  }

  .nav ul li a {
    font-size: 19px;
    line-height: 43px;
  }

}

.brand {
  float: left;
  display: block;
}

.brand img {
  max-width: 100px;
  max-height: 30px;
  padding-top: 8px;
  margin-left: 10px;
}

#home .nav ul {
  padding: 0 5px;
  width: 320px;
  float: none;
  margin: 0 auto;
}

#home .brand {
  width: 100%;
  text-align: center;
}

#home .brand img {
  max-width: 180px;
  max-height: 140px;
  margin: 0 auto;
}

@media screen and (min-width: 768px) {
  #home .brand {
    width: auto;
    text-align: left;
  }

  #home .brand img {
    max-width: 180px;
    max-height: 170px;
    margin-left: 15px;
  }
}

.left {
  width: 100%;
  float: left;
  margin-top: 43px;
  min-height: 5px;
}

.right {
  width: calc(100% - 12px);
  float: left;
  margin: 0 6px;
  margin-bottom: 5px;
  border-top: 3px solid black;
  border-bottom: 3px solid black;
}

@media screen and (min-width: 375px) {
  #home .nav ul {
    padding-top: 25px;
  }
} 

@media screen and (min-width: 768px) {

  #home .nav ul {
    padding: 0;
    float: right;
    width: 50%;
  }

  .nav ul {
    width: 50%;
  }

  .left {
    width: 50%;
  }

  .right {
    width: calc(50% - 24px);
    min-height: calc(100vh - 50px);
    float: left;
    margin: 0 12px;
    margin-top: 43px;
  }
}

@media screen and (min-width: 992px) {

  #home .nav ul {
    width: 40%;
  }

  .nav ul {
    width: 40%;
  }

  .left {
    width: 60%;
  }

  .right {
    width: calc(40% - 24px);
  }
}

@media screen and (min-width: 1200px) {

  #home .nav ul {
    width: 35%;
  }

  .nav ul {
    width: 35%;
  }

  .left {
    width: 65%;
  }

  .right {
    width: calc(35% - 24px);
  }
}

@media screen and (min-width: 1440px) {
  .nav ul {
    width: 35%;
  }

  .left {
    width: 65%;
  }

  .right {
    width: calc(35% - 24px);
  }
}

#home .nav {
  position: absolute;
  top: 0;
  width: 100%;
  background-color: transparent;
}

#home .background {
  width: 100%;
  min-height: calc(100vh);
  background: url('./banner.JPG');
  background-repeat: no-repeat;
  background-size: cover;
}

@media screen and (min-width: 768px) {
  #home .nav {
    position: absolute;
    top: 40px;
    width: 100%;
  }
}

/************************/
/*         About        */
/************************/

#about .gallery {
  min-height: calc(70vh - 50px);
}

#about .gallery .carousel-root {
  margin-left: 6px;
  margin-right: 6px;
}

.carousel-item {
  min-height: calc(69vh - 50px);
  background: url('https://source.unsplash.com/random?sig=1');
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
}

.control-dots {
  display: none;
}

.carousel-status {
  display: none;
}

.carousel .thumbs-wrapper {
  display: none;
}

.carousel .control-arrow, 
.carousel.carousel-slider .control-arrow {
  opacity: 0.5;
}

.carousel .control-arrow:hover, 
.carousel.carousel-slider .control-arrow {
  opacity: 1;
}

.carousel.carousel-slider .control-arrow:hover {
  background: none;
}

.carousel .control-prev.control-arrow {
  left: 20px;
}

.carousel .control-prev.control-arrow:before {
  border: solid white;
  border-width: 0 5px 5px 0;
  display: inline-block;
  padding: 25px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.carousel .control-next.control-arrow {
  right: 20px;
}

.carousel .control-next.control-arrow:before {
  border: solid white;
  border-width: 0 5px 5px 0;
  display: inline-block;
  padding: 25px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.description p {
  margin: 5px 2px;
  margin-bottom: 10px;
  font-size: 22px;
  line-height: 26px;
  font-weight: 500;
  white-space: pre-wrap;
}

@media screen and (min-width: 768px) {

  #about .gallery {
    max-height: calc(100vh - 50px);
  }

  #about .gallery .carousel-root {
    margin-left: 12px;
    margin-right: 0;
  }

  .carousel-item {
    min-height: calc(100vh - 50px);
  }
}

@media screen and (min-width: 1280px) {
  .description p {
    font-size: 22px;
  }
}


@media screen and (min-width: 1680px) {
  .description p {
    font-size: 27px;
    line-height: 32px;
  }
}


@media screen and (min-width: 2160px) {
  .description p {
    font-size: 34px;
    line-height: 38px;
  }
}

/************************/
/*       CONTACT        */
/************************/

.placecard {
  z-index: 9999;
  position: absolute;
}

.placecard-container {
  box-shadow: rgba(0, 0, 0, 0.298039) 0px 1px 4px -1px;
  max-width: 330px;
  width: 100%;
  background: rgb(255, 255, 255) none repeat scroll 0% 0% / auto padding-box border-box;
  border-radius: 2px 2px 2px 2px;
  font: normal normal normal normal 11px / normal Roboto, Arial, sans-serif;
  margin: 10px;
  padding: 9px 4px 9px 11px;
  overflow: hidden;
}

.placecard-left {
  float: left;
  width: 75%;
}

.placecard-right {
  text-align: center;
  float: left;
  width: 25%;
}

.placecard-business-name {
  cursor: default;
  height: 19px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 200px;
  perspective-origin: 100px 9.5px;
  transform-origin: 100px 9.5px;
  font: normal normal 500 normal 14px / normal Roboto, Arial;
  overflow: hidden;
  margin: 0;
}

.placecard-info {
  color: rgb(91, 91, 91);
  cursor: default;
  width: 200px;
  column-rule-color: rgb(91, 91, 91);
  perspective-origin: 100px 16px;
  transform-origin: 100px 16px;
  border: 0px none rgb(91, 91, 91);
  font: normal normal normal normal 12px / normal Roboto, Arial;
  margin: 6px 0px 0px;
  outline: rgb(91, 91, 91) none 0px;
}

.placecard-direction-icon {
  background: rgba(0, 0, 0, 0) url("https://maps.gstatic.com/mapfiles/embed/images/entity11.png") repeat scroll 0px 0px / 70px 210px padding-box border-box;
  height: 22px;
  width: 22px;
  margin-right: auto;
  margin-left: auto;
}

.placecard-direction-link {
  color: rgb(58, 132, 223);
  display: block;
  height: 43px;
  text-decoration: none;
  width: 54.7344px;
}

.placecard-view-large {
  display: block;
  margin-top: 10px;
  color: rgb(58, 132, 223);
  text-decoration: none;
}.placecard-container {
  box-shadow: rgba(0, 0, 0, 0.298039) 0px 1px 4px -1px;
  max-width: 330px;
  width: 100%;
  background: rgb(255, 255, 255) none repeat scroll 0% 0% / auto padding-box border-box;
  border-radius: 2px 2px 2px 2px;
  font: normal normal normal normal 11px / normal Roboto, Arial, sans-serif;
  margin: 10px;
  padding: 9px 4px 9px 11px;
  overflow: hidden;
}

.placecard-left {
  float: left;
  width: 75%;
}

.placecard-right {
  text-align: center;
  float: left;
  width: 25%;
}

.placecard-business-name {
  cursor: default;
  height: 19px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 200px;
  perspective-origin: 100px 9.5px;
  transform-origin: 100px 9.5px;
  font: normal normal 500 normal 14px / normal Roboto, Arial;
  overflow: hidden;
  margin: 0;
}

.placecard-info {
  color: rgb(91, 91, 91);
  cursor: default;
  width: 200px;
  column-rule-color: rgb(91, 91, 91);
  perspective-origin: 100px 16px;
  transform-origin: 100px 16px;
  border: 0px none rgb(91, 91, 91);
  font: normal normal normal normal 12px / normal Roboto, Arial;
  margin: 6px 0px 0px;
  outline: rgb(91, 91, 91) none 0px;
}

.placecard-direction-icon {
  background: rgba(0, 0, 0, 0) url("https://maps.gstatic.com/mapfiles/embed/images/entity11.png") repeat scroll 0px 0px / 70px 210px padding-box border-box;
  height: 22px;
  width: 22px;
  margin-right: auto;
  margin-left: auto;
}

.placecard-direction-link {
  color: rgb(58, 132, 223);
  display: block;
  height: 43px;
  text-decoration: none;
  width: 54.7344px;
}

.placecard-view-large {
  display: block;
  margin-top: 10px;
  color: rgb(58, 132, 223);
  text-decoration: none;
}


#contact .left.map {
  padding-left: 12px;
}

#contact .description {
  position: relative;
}

#contact .contact-form input:first-child {
  margin-top: 10px;
}

#contact .contact-form input,
#contact .contact-form textarea {
  width: 100%;
  height: 38px;
  padding: 5px 10px;
  margin-bottom: 10px;
  background: none;
  border: 1px;
  background-color: #EDEDED;
}

#contact .contact-form .btn {
  width: 87px;
  display: block;
  cursor: pointer;
  padding: 10px 15px;
  border-radius: 100px;
  margin-bottom: 10px;
  background: none;
  border: 1px;
  background-color: #EDEDED;
  color: black;
  text-decoration: none;
  text-align: center;
}

#contact .contact-form .btn:hover,
#contact .contact-form .btn:focus {
  outline: none;
  color: white;
  background-color: black;
}

#contact .contact-form textarea {
  height: 190px;
  resize: none;
}

#contact .contact-form input[type=text]:focus,
#contact .contact-form textarea:focus {
  outline: solid 1px black
}

#contact .right {
  margin-bottom: 4px;
}

#contact .contact-info {
  width: 100%;
  padding: 8px 4px;
  border-top: 1px solid black;
}

#contact .contact-info span {
  display: block;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
}

#contact .contact-details {
  width: 70%;
  float: left;
  position: relative;
  display: inline-block;
}

#contact .contact-details .contact-mail-number {
  padding-top: 20px;
}

#contact .contact-links {
  width: 30%;
  float: left;
  display: inline-block;
}

#contact .contact-links a {
  color: black;
  display: block;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  text-decoration: none;
}

#contact .contact-links a:hover,
#contact .contact-links a:focused {
  text-decoration: underline;
}

@media screen and (min-width: 762px) and (min-height: 675px) {
  #contact .contact-info {
    bottom: 10px;
    padding: 4px 4px;
    position: absolute;
  }
}

.wine-gallery::-webkit-scrollbar { 
  display: none; 
}

.wine-gallery-item {
  width: 100%;
  float: left;
  display: inline-block;
}

@media screen and (min-width: 768px) {

  #contact .right {
    margin: 0 12px;
    margin-top: 43px;
  }

  #contact .contact-info {
    padding: 0;
    padding-top: 4px;
  }

  #contact .contact-info span {
    font-size: 20px;
    line-height: 23px;
  }

  #contact .contact-links a {
    font-size: 20px;
    line-height: 23px;
  }

  .left.wine-gallery {
    padding-left: 7px;
  }
}

@media screen and (min-width: 992px) {
  .wine-gallery-item {
    width: 50%;
  }

}

@media screen and (min-width: 1200px) {
  .wine-gallery-item {
    width: 33.33%;
  }
}

.wine-bottle {
  display: block;
  position: relative;
  margin: 0px 5px 8px 5px;
  background-color: #EDEDED;
}

.wine-bottle .wine-bottle-thumb {
  min-height: 400px;
  background-size: cover;
  background-position: top center;
}

@media screen and (min-width: 2160px) {
  .wine-bottle .wine-bottle-thumb {
    min-height: 600px;
  }
}

.wine-bottle img {
  margin: 0 auto;
  display: block;
  height: 350px;
}

.wine-bottle .wine-info {
  display: block;
}

@media screen and (min-width: 768px) {
  .wine-gallery {
    overflow-y: scroll;
    height: calc(100vh - 50px);
  }
}

@media screen and (min-width: 992px) {
  .wine-bottle .wine-info {
    display: none;
  }

  .wine-bottle:hover .wine-info {
    display: block;
  }
}


.wine-bottle .wine-info {
  left: .3rem;
  bottom: 2rem;
  position: absolute;
}

.wine-bottle .wine-label {
  display: block;
  margin-bottom: 8px; 
}

.wine-bottle .wine-label .wine-label-baloon {
  color: white;
  font-size: 15px;
  font-weight: bold;
  line-height: 15px;
  padding: .2rem .8rem;
  border-radius: 13px;
  text-transform: capitalize;
  background-color: black;
}

.content {
  position: relative;
}

.wine-image {
  position: relative;
  background-size: cover;
  background-position: top center;
  /*background-color: #EDEDED;*/
  height: calc(85vh - 50px);
}

.wine-image img {
  top: 50%;
  left: 50%;
  margin: 0 auto;
  max-width: 100%;
  max-height: 75vh;
  position: absolute;
  transform: translate(-50%, -50%);
}

.wine-image .previous {
  top: 40%;
  left: 30px;
  position: absolute;
  border: solid black;
  border-width: 0 5px 5px 0;
  display: inline-block;
  padding: 25px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.wine-image .next {
  top: 40%;
  right: 30px;
  position: absolute;
  border: solid black;
  border-width: 0 5px 5px 0;
  display: inline-block;
  padding: 25px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.wine-header {
  position: relative;
}

.wine-header .wine-title {
  color: #000;
  font-size: 30px;
  font-weight: 500;
  line-height: 31px;
  margin-top: 5px;
  margin-bottom: 0;
}

.wine-header .wine-subtitle {
  color: #999;
  font-size: 30px;
  font-weight: 500;
  line-height: 31px;
}

.wine-header .wine-close {
  position: absolute;
  top: 2px;
  right: 0;
}

.wine-header .wine-section {
  
}

.wine-section .wine-section-title {
  font-size: 13px;
  line-height: 22px;
  margin-top: 18px;
  text-transform: uppercase;
  border-bottom: 2px solid black;
}

.wine-section .wine-section-description {
  font-size: 20px;
  margin-top: 6px;
  line-height: 24px;
  white-space: pre-wrap;
}

.wine-section.bottom {
  width: 100%;
}

.wine-section-col {
  width: 100%;
  float: left;
  margin-bottom: 10px;
  display: inline-block;
}

@media screen and (min-width: 768px) {
  .wine-image {
    height: calc(100vh - 50px);
  }
}

@media screen and (min-width: 992px) and (min-height: 700px) {
  .wine-section.bottom {
    bottom: 12px;
    position: absolute;
  }
}

@media screen and (min-width: 992px) {
  .wine-image img {
    max-height: 100vh;
  }

  .wine-section-col {
    width: 33%;
  }
}

.wine-section-col .wine-section-col-description {
  font-size: 19px;
  line-height: 22px;
}


.wine-section-col .wine-section-col-title {
  font-weight: bold;
  font-size: 19px;
  line-height: 22px;
}

.wine-section-col .wine-section-col-title.alcohol,
.wine-section-col .wine-section-col-title.harvest {
  padding-top: 12px;
}

.wine-section-col .wine-section-col-value {
  padding-left: 12px;
  text-indent: -12px;
}

.close-button {
   display: block;
   width: 30px;
   height: 30px;
   position: relative;
   overflow: hidden;
}
 .close-button > div {
   position: relative;
}
 .close-button-block {
   width: 30px;
   height: 15px;
   position: relative;
   overflow: hidden;
}
 .close-button-block:before, .close-button-block:after {
   content: '';
   position: absolute;
   bottom: 0;
   left: calc(55% - 2.5px);
   display: block;
   width: 2px;
   height: 25px;
   transform-origin: bottom center;
   background: black;
   transition: all ease-out 280ms;
}
 .close-button-block:last-of-type {
   transform: rotate(180deg);
}
 .close-button .in .close-button-block:before {
   transition-delay: 280ms;
   transform: translateX(20px) translateY(-20px) rotate(45deg);
}
 .close-button .in .close-button-block:after {
   transition-delay: 280ms;
   transform: translateX(-22px) translateY(-22px) rotate(-45deg);
}
 .close-button .out {
   position: absolute;
   top: 0;
   left: 0;
}
 .close-button .out .close-button-block:before {
   transform: translateX(-5px) translateY(5px) rotate(45deg);
}
 .close-button .out .close-button-block:after {
   transform: translateX(5px) translateY(5px) rotate(-45deg);
}
 .close-button:hover .in .close-button-block:before {
   transform: translateX(-5px) translateY(5px) rotate(45deg);
}
 .close-button:hover .in .close-button-block:after {
   transform: translateX(5px) translateY(5px) rotate(-45deg);
}
 .close-button:hover .out .close-button-block:before {
   transform: translateX(-20px) translateY(20px) rotate(45deg);
}
 .close-button:hover .out .close-button-block:after {
   transform: translateX(20px) translateY(20px) rotate(-45deg);
}
